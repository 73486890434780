import React, { ReactElement } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { Menu } from '@styled-icons/material'

import { List_Inline, ListItem_Inline } from '~/components/atoms'
import { useAppContext } from '~/contexts'
import { SiteConfig } from '~/config'

import { HeaderAnchor } from '../atoms'

//----- Styling -----//

const MenuButton = styled.button`
	margin: 0;
	padding: 0;
	width: 30px;

	background-color: transparent;
	border: 0;
`

const SiteNavList = styled(List_Inline)`
	font-size: 0.75em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
`

//----- Component -----//

export const SiteNav = (): ReactElement => {
	const { toggleSlideoutMenu } = useAppContext()

	const { url, utmSource } = SiteConfig.storeInfo
	const storeUrl = `${url}?utm_source=${utmSource}&utm_medium=website&utm_campaign=site_header`

	return (
		<SiteNavList gap={1.8}>
			<ListItem_Inline hideOnDesktop>
				<MenuButton onClick={toggleSlideoutMenu}>
					<Menu />
				</MenuButton>
			</ListItem_Inline>

			<ListItem_Inline hideOnMobile>
				<Link href="/magazine" passHref legacyBehavior>
					<HeaderAnchor>The Magazine</HeaderAnchor>
				</Link>
			</ListItem_Inline>

			<ListItem_Inline hideOnMobile>
				<HeaderAnchor href={storeUrl}>Shop</HeaderAnchor>
			</ListItem_Inline>
		</SiteNavList>
	)
}
