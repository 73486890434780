import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor_External } from '~/components/atoms'
import { SiteConfig } from '~/config'

const Accessibility = styled.div`
	margin-top: 8px;
`

export const Copyright = (): ReactElement => {
	const { accessibilityUrl, copyrightUrl } = SiteConfig.footerInfo
	const currentYear = new Date().getFullYear()

	return (
		<>
			<div>
				<span>Copyright &copy; {currentYear} </span>
				<Anchor_External to={copyrightUrl}>Kalmbach Media Co.</Anchor_External>
			</div>
			<Accessibility>
				<Anchor_External to={accessibilityUrl}>
					Website Accessibility
				</Anchor_External>
			</Accessibility>
		</>
	)
}
