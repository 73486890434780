import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { transparentize as fade } from 'polished'

import { Anchor, BlockDescriptor } from '~/components/atoms'
import { Carousel, ProductCard } from '~/components/molecules'
import { SiteConfig } from '~/config'
import type { ProductCardData } from '~/types/content'

//----- Styling -----//

const SectionFooter = styled.hr`
	margin-top: 32px;
	border: 1px solid ${(p) => p.theme.color.gray[13]};
`

const StyledSection = styled.section`
	margin-bottom: 4em;
`

// TODO: Could this be a Button atom variant?
const CalloutLink = styled(Anchor)`
	display: block;
	margin: 16px auto 0;
	padding: 16px 0;
	width: 142px;

	font-size: 0.75em;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	letter-spacing: ${(p) => p.theme.font.sans.spacing.wide};
	text-transform: uppercase;

	color: ${(p) => p.theme.color.secondary.contrast};
	background-color: ${(p) => p.theme.color.secondary.base};

	text-align: center;
	transition: box-shadow 350ms ease-in-out;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		box-shadow: 0 0 8px 0px
			${(p) => fade(0.8, p.theme.color.secondary.contrast)};
	}
`

//----- Component -----//

interface ProductAreaProps {
	products?: ProductCardData[]
	noFooter?: boolean
}

export const ProductArea = (props: ProductAreaProps): ReactElement => {
	const { products, noFooter } = props

	return (
		<StyledSection>
			<BlockDescriptor marginTop marginBottom>
				Recommendations From Our Store
			</BlockDescriptor>

			{products && (
				<Carousel>
					{products.map((product, i) => (
						<ProductCard key={i} product={product} />
					))}
				</Carousel>
			)}

			<CalloutLink to={SiteConfig.storeInfo.url}>Shop Now</CalloutLink>

			{!noFooter && <SectionFooter />}
		</StyledSection>
	)
}
